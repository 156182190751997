import * as React from "react";
import { graphql } from "gatsby";
import Player from "../components/elements/player";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import BlocNews from "../components/elements/bloc-news";
import { imgConvertToWebP } from "../utils";
import BlocTitle from "../components/elements/bloc-title";

const IndexPage = ({ data }) => {
  console.log(imgConvertToWebP(data.strapiHome.ImageBackground.url));
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiHome.SEO.metaTitle}</title>
        <meta
          name="description"
          content={data.strapiHome.SEO.metaDescription}
        />

        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="bg-black font-gotham text-white">
        <BlocTitle styleName="text-4xl font-gotham-ultra uppercase text-center py-5">
          {data.strapiHome.secondaryTitle}
        </BlocTitle>
        {/* <h1 className="m-auto text-center px-6 pt-2 pb-1 font-gotham-ultra uppercase text-white md:text-4xl lg:px-12 lg:text-6xl">
          {data.strapiHome.secondaryTitle}
        </h1> */}
        <div className="h-1/2 pb-8 xl:pb-10">
          <Player urlVideo={data.strapiHome.urlVideo} />
        </div>
        <div className="pb-14">
          {data.strapiHome.ImgBackgroundVisible ? (
            <img
              alt="background"
              className="z-0 h-auto w-fit pt-8 pb-6 lg:pt-10 lg:pt-8 bg-black lg:h-fit lg:w-full lg:object-cover"
              src={imgConvertToWebP(data.strapiHome.ImageBackground.url).src}
            />
          ) : (
            ""
          )}
          <div className="grid grid-cols-2 justify-items-center gap-x-8 text-xs md:text-base font-gotham-ultra uppercase text-white leading-5 lg:leading-10 activities">
            <ul className="col-span-2 text-center lg:col-span-1 lg:justify-self-end lg:text-right">
              <li>{data.strapiHome.reassuranceOne}</li>
              <li className="">{data.strapiHome.reassuranceTwo}</li>
            </ul>
            <ul className="col-span-2 text-center lg:text-left lg:col-span-1 lg:justify-self-start">
              <li>{data.strapiHome.reassuranceThree}</li>
              <li>{data.strapiHome.reassuranceFour}</li>
            </ul>
            <p className="col-span-2 m-auto justify-items-stretch">
              {data.strapiHome.reassuranceFive}
            </p>
          </div>
        </div>
        <div className="flex flex-col mb-10">
          <BlocNews />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    strapiHome {
      urlVideo
      ImageBackground {
        url
      }
      ImgBackgroundVisible
      secondaryTitle
      reassuranceOne
      reassuranceTwo
      reassuranceThree
      reassuranceFour
      reassuranceFive
      SEO {
        metaTitle
        metaDescription
      }
    }
  }
`;
export default IndexPage;
