import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import _map from "lodash/map";
import BlocImage from "./bloc-image";
import BlocVideo from "./bloc-video";
import BlocTitle from "./bloc-title";

const PAGE_SIZE = 5;

function BlocArticle({ d, index }) {
    return (
        <div
            className="w-full lg:w-2/3 bg-pink p-6 mt-10 self-center max-w-3xl"
            key={index}
        >
            <div className="flex flex-col mb-5 justify-center md:block">
                {d.HeadingImage && (
                    <BlocImage
                        className="md:mr-5 mb-8 md:mb-4 w-full md:w-[315px] md:float-left md:min-w-1/2"
                        src={d.HeadingImage.url}
                        alt={d.HeadingImage.alt}
                        animate="None"
                        mode="fill"
                        width="315"
                        height="315"
                    />
                )}
                <div className="w-full">
                    {d.Title && (
                        <BlocTitle styleName="text-4xl font-gotham-ultra uppercase mb-5">
                            {d.Title}
                        </BlocTitle>
                    )}
                    {d.ArticleShort && d.ArticleShort.data && (
                        <div
                            className="text-sm md:text-base"
                            dangerouslySetInnerHTML={{
                                __html: d.ArticleShort.data.ArticleShort,
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="clear-both"></div>
            {d.HeadingWebm && (
                <BlocVideo
                    className="mt-4"
                    src={d.HeadingWebm[0].url}
                    alt={d.HeadingImage.alt}
                    width="720"
                    height="390"
                />
            )}
        </div>
    );
}
export default function BlocNews() {
    const data = useStaticQuery(graphql`
        query allStrapiArticlesQuery {
            allStrapiArticle(
                sort: { fields: [sortOrder, updatedAt], order: [DESC, DESC] }
            ) {
                nodes {
                    Title
                    id
                    slug
                    HeadingWebm {
                        url
                        width
                        height
                        alternativeText
                    }
                    ArticleShort {
                        data {
                            ArticleShort
                        }
                    }
                    HeadingImage {
                        alternativeText
                        height
                        width
                        url
                    }
                    sortOrder
                    updatedAt
                }
            }
        }
    `);

    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const articles = data.allStrapiArticle.nodes.slice(startIndex, endIndex);

    const [newsList, setNewsList] = useState(
        _map(articles, (d, index) => {
            return <BlocArticle index={index} d={d} slug={d.slug} />;
        })
    );

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
        const newStartIndex = currentPage * PAGE_SIZE + PAGE_SIZE;
        const newEndIndex = newStartIndex + PAGE_SIZE;
        const newArticles = data.allStrapiArticle.nodes.slice(
            newStartIndex,
            newEndIndex
        );
        setNewsList([
            ...newsList,
            ..._map(newArticles, (d, index) => {
                return <BlocArticle index={index} d={d} slug={d.slug} />;
            }),
        ]);
    };

    return (
        <>
            <header>
                <h1 className="m-auto max-w-full bg-pink text-center px-6 pt-7 pb-5 font-gotham-ultra uppercase text-white md:text-4xl lg:px-12">
                    What's new ?
                </h1>
            </header>
            {newsList}
            {endIndex < data.allStrapiArticle.nodes.length && (
                <div className="flex justify-center mt-5">
                    <button
                        onClick={handleLoadMore}
                        className="bg-pink text-white py-2 px-4 uppercase font-gotham-ultra"
                    >
                        Voir Plus
                    </button>
                </div>
            )}
        </>
    );
}
